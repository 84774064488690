<template>
    <div class="full-page" title="">
        <div class="card bs-5 w-50r">
            <h5 class="text-primary mb-4">Update Password</h5>
            <s-form @submit="changePassword" ref="form">
                <validated-input placeholder="Enter Old Password" type="password" label="Current Password"
                                 name="Current Password" v-model="model.current_password"
                                 :disabled="loading" :rules="{required:true}"/>
                <validated-input placeholder="Enter New Password" label="New Password" name="New Password"
                                 type="password" v-model="model.new_password"
                                 :disabled="loading" :rules="{required:true}"/>
                <validated-input placeholder="Confirm New Password" type="password" label="Confirm Password"
                                 name="Confirm Password" v-model="model.confirm_password"
                                 :disabled="loading" :rules="{required:true}"/>
                <div class="fl-x-c mt-4">
                    <btn class="btn-block" @click="$router.go(-1)" type="button" text="Back" size="sm"
                         :disabled="loading" :loading="loading"/>
                    <btn class="btn-block ml-2" text="Update" loading-text="Processing..." size="sm"
                         :disabled="loading" :loading="loading"/>
                </div>
            </s-form>
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'UpdatePassword',
    data () {
        return {
            loading: false,
            model: {
                old_password: '',
                new_password: '',
                confirm_password: ''
            }
        };
    },
    methods: {
        async changePassword () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.updatePassword, that.model);
            const json = response.data;
            if (json.success === true) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$notify('Updated Successfully', 'Success', {
                type: 'success'
            });
            this.$router.push('/admin/login/');
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }
    }
};
</script>
<style scoped>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
</style>
